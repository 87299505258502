<template>
  <layout-banner>
    <b-row class="px-2 pb-5">
      <b-col cols="12" class="pb-5">
        <Stepper :steps="steps" :flowStep="flowStep" :activeStep="activeStep" @setStep="setStep" />
        <b-row>
          <b-col cols="12">
            <FormIdentificacion v-if="activeStep == 0" @nextStep="nextStep" />
            <FormCrearUsuario v-if="activeStep == 1" @nextStep="nextStep" />
            <FormValidarIdentidad v-if="activeStep == 2" @nextStep="nextStep" />
            <FormConfirmarIdentidad v-if="activeStep == 3" @nextStep="nextStep" />
          </b-col>
        </b-row>
      </b-col>
    </b-row>
  </layout-banner>
</template>

<script>
import mixinsEvents from "@/app/shared/mixins/mixins-events";

// COMPONENTS
import Stepper from "@/app/shared/components/Stepper.vue";
import LayoutBanner from "@/app/shared/layouts/Banner.vue";
import FormCrearUsuario from "../components/FormCrearUsuario.vue";
import FormValidarIdentidad from "../components/FormValidarIdentidad.vue";
import FormIdentificacion from "@/app/shared/components/FormIdentificacion.vue";
import FormConfirmarIdentidad from "@/app/shared/components/FormConfirmarIdentidad.vue";

export default {
  mixins: [mixinsEvents],
  components: {
    Stepper,
    LayoutBanner,
    FormCrearUsuario,
    FormIdentificacion,
    FormValidarIdentidad,
    FormConfirmarIdentidad,
  },
  data() {
    return {
      flowStep: 0,
      activeStep: 0,
      steps: [
        { name: "Ingresa tu identificación" },
        { name: "Crea tu usuario y contraseña" },
        { name: "Valida tu identidad" },
        { name: "Confirmar tu identidad" },
      ],
    };
  },
};
</script>
