<template>
  <b-row class="px-2">
    <b-col cols="12">
      <h4 class="primary fw-bold">Valida tu identidad</h4>
      <p class="mt-3 fs-14" align="justify">
        Por favor responde las siguientes preguntas para validar tu identidad. Selecciona las respuestas que consideres
        correctas y haz clic en el botón aceptar para continuar.
      </p>
    </b-col>
    <b-col cols="12" class="pt-2" :key="pregunta.codigo.$model" v-for="(pregunta, index) in $v.preguntas.$each.$iter">
      <p class="primary fw-bold mb-1">Pregunta {{ parseInt(index) + 1 }}</p>
      <b-form-group :label="pregunta.pregunta.$model">
        <b-form-select v-model="pregunta.valor.$model" :state="pregunta.valor.$dirty ? !pregunta.valor.$error : null">
          <b-form-select-option value="">Seleccione</b-form-select-option>
          <b-form-select-option :key="idx" :value="item.idRespuesta" v-for="(item, idx) in pregunta.respuestas.$model">
            {{ item.respuesta }}
          </b-form-select-option>
        </b-form-select>
        <b-form-invalid-feedback>{{ validarCampo(pregunta.valor) }}</b-form-invalid-feedback>
      </b-form-group>
    </b-col>
    <b-col cols="12" class="pt-3 d-flex justify-content-end">
      <b-button variant="danger" @click="validarPreguntas"> <i class="las la-check-circle" /> Aceptar </b-button>
    </b-col>
  </b-row>
</template>

<script>
import { required } from "vuelidate/lib/validators";
import mixinsEvents from "@/app/shared/mixins/mixins-events";
import AlertsService from "@/app/shared/services/alerts.service";
import ValidacionIdentidadService from "@/app/core/api/validacion-identidad";

export default {
  mixins: [mixinsEvents],
  data() {
    return { preguntas: [] };
  },
  validations: {
    preguntas: { $each: { codigo: {}, pregunta: {}, solicitud: {}, respuestas: {}, valor: { required } } },
  },
  created() {
    this.getPreguntas();
  },
  methods: {
    getPreguntas() {
      const body = {
        numeroDocumento: this.$store.getters.dataShared.cedula,
        origenDatos: this.$store.getters.dataShared.origenDatos,
        tipoDocumento: this.$store.getters.dataShared.tipoIdentificacion,
      };

      ValidacionIdentidadService.getPreguntas(body).then((response) => {
        if (response.data.estado) this.preguntas = response.data.listPreguntas;
        else AlertsService.error("Registro", "No se han encontrado preguntas para realizar la validación.");
      });
    },
    validarPreguntas() {
      this.$v.$touch();
      if (this.$v.$invalid) return;

      const respuestas = [];
      let idSolicitud = null;

      this.preguntas.map((item) => {
        if (idSolicitud === null) idSolicitud = item.solicitud;
        respuestas.push({ preguntaId: parseInt(item.codigo), idDetalle: item.valor });
      });

      ValidacionIdentidadService.validarPreguntas({ idSo: idSolicitud, detalle: respuestas }).then((response) => {
        if (response.data.estado) {
          AlertsService.success("Registro", "Tu identidad fue validada correctamente.");
          this.$store.commit("updateDataShared", { idSolicitud: idSolicitud });
          this.$emit("nextStep");
        } else {
          AlertsService.error("Registro", response.data.mensaje);
          this.preguntas = response.data.listPreguntas;
          this.$nextTick(() => this.$v.$reset());
        }
      });
    },
  },
};
</script>
