var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "layout-banner",
    [
      _c(
        "b-row",
        { staticClass: "px-2 pb-5" },
        [
          _c(
            "b-col",
            { staticClass: "pb-5", attrs: { cols: "12" } },
            [
              _c("Stepper", {
                attrs: {
                  steps: _vm.steps,
                  flowStep: _vm.flowStep,
                  activeStep: _vm.activeStep,
                },
                on: { setStep: _vm.setStep },
              }),
              _c(
                "b-row",
                [
                  _c(
                    "b-col",
                    { attrs: { cols: "12" } },
                    [
                      _vm.activeStep == 0
                        ? _c("FormIdentificacion", {
                            on: { nextStep: _vm.nextStep },
                          })
                        : _vm._e(),
                      _vm.activeStep == 1
                        ? _c("FormCrearUsuario", {
                            on: { nextStep: _vm.nextStep },
                          })
                        : _vm._e(),
                      _vm.activeStep == 2
                        ? _c("FormValidarIdentidad", {
                            on: { nextStep: _vm.nextStep },
                          })
                        : _vm._e(),
                      _vm.activeStep == 3
                        ? _c("FormConfirmarIdentidad", {
                            on: { nextStep: _vm.nextStep },
                          })
                        : _vm._e(),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }