var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "b-row",
    { staticClass: "px-2" },
    [
      _c("b-col", { attrs: { cols: "12" } }, [
        _c("h4", { staticClass: "primary fw-bold" }, [
          _vm._v("Crea tu usuario y contraseña"),
        ]),
        _c("p", { staticClass: "mt-3 fs-14 text-justify" }, [
          _vm._v(
            " Diligencia los datos del formulario para crear tu cuenta en la Sucursal Virtual ProgreSER, la información de correo electrónico y número de celular son importantes para la activación de tu cuenta. Por favor verifica que la información diligenciada sea la correcta. "
          ),
        ]),
        _c("p", { staticClass: "mb-1 fs-14 text-justify" }, [
          _vm._v(
            " Ten en cuenta que tu contraseña debe cumplir con lo siguiente: "
          ),
        ]),
        _c("p", { staticClass: "mb-0 fs-14" }, [
          _vm._v(
            "* Ser de mínimo " +
              _vm._s(_vm.minLength) +
              " caracteres y máximo " +
              _vm._s(_vm.maxLength) +
              "."
          ),
        ]),
        _c("p", { staticClass: "mb-0 fs-14" }, [
          _vm._v("* Debe ser Alfanumérica."),
        ]),
        _c("p", { staticClass: "mb-0 fs-14" }, [
          _vm._v(
            "* Debe tener mínimo " +
              _vm._s(_vm.specialCharacters) +
              " caracteres especiales."
          ),
        ]),
        _c("p", { staticClass: "mb-0 fs-14" }, [
          _vm._v("* No puede ser igual a tu identificación."),
        ]),
        _c("p", { staticClass: "mb-0 fs-14" }, [
          _vm._v("* Deben coincidir los campos de la contraseña."),
        ]),
        _c("p", { staticClass: "mb-1 fs-14  text-justify" }, [
          _vm._v(
            " Durante el proceso te informaremos el nivel de seguridad de la contraseña de la siguiente manera: "
          ),
          _c("img", {
            staticClass: "ml-4",
            attrs: { src: require("@/assets/images/anothers/meter.png") },
          }),
        ]),
      ]),
      _c(
        "b-col",
        { staticClass: "pb-4", attrs: { cols: "12" } },
        [
          _c(
            "b-form-row",
            [
              _c(
                "b-col",
                { attrs: { cols: "12", md: "6", lg: "4" } },
                [
                  _c(
                    "b-form-group",
                    { attrs: { label: "Primer Nombre *" } },
                    [
                      _c("b-form-input", {
                        attrs: {
                          state: _vm.$v.formR.primerNombre.$dirty
                            ? !_vm.$v.formR.primerNombre.$error
                            : null,
                        },
                        model: {
                          value: _vm.$v.formR.primerNombre.$model,
                          callback: function ($$v) {
                            _vm.$set(_vm.$v.formR.primerNombre, "$model", $$v)
                          },
                          expression: "$v.formR.primerNombre.$model",
                        },
                      }),
                      _c("b-form-invalid-feedback", [
                        _vm._v(
                          _vm._s(_vm.validarCampo(_vm.$v.formR.primerNombre))
                        ),
                      ]),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "b-col",
                { attrs: { cols: "12", md: "6", lg: "4" } },
                [
                  _c(
                    "b-form-group",
                    { attrs: { label: "Segundo Nombre" } },
                    [
                      _c("b-form-input", {
                        attrs: {
                          state: _vm.$v.formR.segundoNombre.$dirty
                            ? !_vm.$v.formR.segundoNombre.$error
                            : null,
                        },
                        model: {
                          value: _vm.$v.formR.segundoNombre.$model,
                          callback: function ($$v) {
                            _vm.$set(_vm.$v.formR.segundoNombre, "$model", $$v)
                          },
                          expression: "$v.formR.segundoNombre.$model",
                        },
                      }),
                      _c("b-form-invalid-feedback", [
                        _vm._v(
                          _vm._s(_vm.validarCampo(_vm.$v.formR.segundoNombre))
                        ),
                      ]),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "b-col",
                { attrs: { cols: "12", md: "6", lg: "4" } },
                [
                  _c(
                    "b-form-group",
                    { attrs: { label: "Primer Apellido *" } },
                    [
                      _c("b-form-input", {
                        attrs: {
                          state: _vm.$v.formR.primerApellido.$dirty
                            ? !_vm.$v.formR.primerApellido.$error
                            : null,
                        },
                        model: {
                          value: _vm.$v.formR.primerApellido.$model,
                          callback: function ($$v) {
                            _vm.$set(_vm.$v.formR.primerApellido, "$model", $$v)
                          },
                          expression: "$v.formR.primerApellido.$model",
                        },
                      }),
                      _c("b-form-invalid-feedback", [
                        _vm._v(
                          _vm._s(_vm.validarCampo(_vm.$v.formR.primerApellido))
                        ),
                      ]),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "b-col",
                { attrs: { cols: "12", md: "6", lg: "4" } },
                [
                  _c(
                    "b-form-group",
                    { attrs: { label: "Segundo Apellido" } },
                    [
                      _c("b-form-input", {
                        attrs: {
                          state: _vm.$v.formR.segundoApellido.$dirty
                            ? !_vm.$v.formR.segundoApellido.$error
                            : null,
                        },
                        model: {
                          value: _vm.$v.formR.segundoApellido.$model,
                          callback: function ($$v) {
                            _vm.$set(
                              _vm.$v.formR.segundoApellido,
                              "$model",
                              $$v
                            )
                          },
                          expression: "$v.formR.segundoApellido.$model",
                        },
                      }),
                      _c("b-form-invalid-feedback", [
                        _vm._v(
                          _vm._s(_vm.validarCampo(_vm.$v.formR.segundoApellido))
                        ),
                      ]),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "b-col",
                { attrs: { cols: "12", md: "6", lg: "4" } },
                [
                  _c(
                    "b-form-group",
                    { attrs: { label: "Celular *" } },
                    [
                      _c("b-form-input", {
                        directives: [
                          {
                            name: "mask",
                            rawName: "v-mask",
                            value: ["(###) ###-####"],
                            expression: "['(###) ###-####']",
                          },
                        ],
                        attrs: {
                          state: _vm.$v.formR.celular.$dirty
                            ? !_vm.$v.formR.celular.$error
                            : null,
                        },
                        on: { change: _vm.validarCelular },
                        model: {
                          value: _vm.$v.formR.celular.$model,
                          callback: function ($$v) {
                            _vm.$set(_vm.$v.formR.celular, "$model", $$v)
                          },
                          expression: "$v.formR.celular.$model",
                        },
                      }),
                      _c("b-form-invalid-feedback", [
                        _vm._v(_vm._s(_vm.validarCampo(_vm.$v.formR.celular))),
                      ]),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "b-col",
                { attrs: { cols: "12", md: "6", lg: "4" } },
                [
                  _c(
                    "b-form-group",
                    { attrs: { label: "Dirección *" } },
                    [
                      _c("b-form-input", {
                        attrs: {
                          state: _vm.$v.formR.direccion.$dirty
                            ? !_vm.$v.formR.direccion.$error
                            : null,
                        },
                        model: {
                          value: _vm.$v.formR.direccion.$model,
                          callback: function ($$v) {
                            _vm.$set(_vm.$v.formR.direccion, "$model", $$v)
                          },
                          expression: "$v.formR.direccion.$model",
                        },
                      }),
                      _c("b-form-invalid-feedback", [
                        _vm._v(
                          _vm._s(_vm.validarCampo(_vm.$v.formR.direccion))
                        ),
                      ]),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "b-col",
                { attrs: { cols: "12", md: "6", lg: "4" } },
                [
                  _c(
                    "b-form-group",
                    { attrs: { label: "Departamento *" } },
                    [
                      _c("b-form-select", {
                        attrs: {
                          options: _vm.list.codigoDepartamento,
                          state: _vm.$v.formR.codigoDepartamento.$dirty
                            ? !_vm.$v.formR.codigoDepartamento.$error
                            : null,
                        },
                        on: { change: _vm.getCiudadesByDpto },
                        scopedSlots: _vm._u([
                          {
                            key: "first",
                            fn: function () {
                              return [
                                _c(
                                  "b-form-select-option",
                                  { attrs: { value: null } },
                                  [_vm._v("Seleccione")]
                                ),
                              ]
                            },
                            proxy: true,
                          },
                        ]),
                        model: {
                          value: _vm.$v.formR.codigoDepartamento.$model,
                          callback: function ($$v) {
                            _vm.$set(
                              _vm.$v.formR.codigoDepartamento,
                              "$model",
                              $$v
                            )
                          },
                          expression: "$v.formR.codigoDepartamento.$model",
                        },
                      }),
                      _c("b-form-invalid-feedback", [
                        _vm._v(
                          _vm._s(
                            _vm.validarCampo(_vm.$v.formR.codigoDepartamento)
                          )
                        ),
                      ]),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "b-col",
                { attrs: { cols: "12", md: "6", lg: "4" } },
                [
                  _c(
                    "b-form-group",
                    { attrs: { label: "Ciudad *" } },
                    [
                      _c("b-form-select", {
                        attrs: {
                          options: _vm.list.codigoMunicipio,
                          state: _vm.$v.formR.codigoMunicipio.$dirty
                            ? !_vm.$v.formR.codigoMunicipio.$error
                            : null,
                        },
                        scopedSlots: _vm._u([
                          {
                            key: "first",
                            fn: function () {
                              return [
                                _c(
                                  "b-form-select-option",
                                  { attrs: { value: null } },
                                  [_vm._v("Seleccione")]
                                ),
                              ]
                            },
                            proxy: true,
                          },
                        ]),
                        model: {
                          value: _vm.$v.formR.codigoMunicipio.$model,
                          callback: function ($$v) {
                            _vm.$set(
                              _vm.$v.formR.codigoMunicipio,
                              "$model",
                              $$v
                            )
                          },
                          expression: "$v.formR.codigoMunicipio.$model",
                        },
                      }),
                      _c("b-form-invalid-feedback", [
                        _vm._v(
                          _vm._s(_vm.validarCampo(_vm.$v.formR.codigoMunicipio))
                        ),
                      ]),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "b-col",
                { attrs: { cols: "12", md: "6", lg: "4" } },
                [
                  _c(
                    "b-form-group",
                    { attrs: { label: "Correo electrónico *" } },
                    [
                      _c("b-form-input", {
                        attrs: {
                          state: _vm.$v.formR.email.$dirty
                            ? !_vm.$v.formR.email.$error
                            : null,
                        },
                        model: {
                          value: _vm.$v.formR.email.$model,
                          callback: function ($$v) {
                            _vm.$set(_vm.$v.formR.email, "$model", $$v)
                          },
                          expression: "$v.formR.email.$model",
                        },
                      }),
                      _c("b-form-invalid-feedback", [
                        _vm._v(_vm._s(_vm.validarCampo(_vm.$v.formR.email))),
                      ]),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "b-col",
                { attrs: { cols: "12", md: "6", lg: "4" } },
                [
                  _c(
                    "b-form-group",
                    { attrs: { label: "Confirmar correo electrónico *" } },
                    [
                      _c("b-form-input", {
                        attrs: {
                          name: "bloquearPegado",
                          state: _vm.$v.formConfirmR.email.$dirty
                            ? !_vm.$v.formConfirmR.email.$error
                            : null,
                        },
                        model: {
                          value: _vm.$v.formConfirmR.email.$model,
                          callback: function ($$v) {
                            _vm.$set(_vm.$v.formConfirmR.email, "$model", $$v)
                          },
                          expression: "$v.formConfirmR.email.$model",
                        },
                      }),
                      _c("b-form-invalid-feedback", [
                        _vm._v(
                          _vm._s(_vm.validarCampo(_vm.$v.formConfirmR.email))
                        ),
                      ]),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "b-col",
                { attrs: { cols: "12", md: "6", lg: "4" } },
                [
                  _c(
                    "b-form-group",
                    { attrs: { label: "Contraseña *" } },
                    [
                      _c(
                        "b-input-group",
                        [
                          _c("b-form-input", {
                            staticClass: "custom-input-group",
                            attrs: {
                              autocomplete: "new-password",
                              type: _vm.verClave ? "text" : "password",
                              state: _vm.$v.formR.clave.$dirty
                                ? !_vm.$v.formR.clave.$error
                                : null,
                            },
                            model: {
                              value: _vm.$v.formR.clave.$model,
                              callback: function ($$v) {
                                _vm.$set(_vm.$v.formR.clave, "$model", $$v)
                              },
                              expression: "$v.formR.clave.$model",
                            },
                          }),
                          _c(
                            "b-input-group-append",
                            {
                              on: {
                                click: function ($event) {
                                  return _vm.visualizarClave()
                                },
                              },
                            },
                            [
                              _c(
                                "b-button",
                                {
                                  staticClass: "icono-ver-clave px-3",
                                  attrs: { variant: "light" },
                                },
                                [
                                  _c("i", {
                                    class:
                                      "las la-eye" +
                                      (_vm.verClave ? "-slash" : "") +
                                      " fs-22 primary d-flex",
                                  }),
                                ]
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _c("b-form-invalid-feedback", [
                        _vm._v(_vm._s(_vm.validarCampo(_vm.$v.formR.clave))),
                      ]),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "b-col",
                { attrs: { cols: "12", md: "6", lg: "4" } },
                [
                  _c(
                    "b-form-group",
                    { attrs: { label: "Confirmar contraseña *" } },
                    [
                      _c(
                        "b-input-group",
                        [
                          _c("b-form-input", {
                            staticClass: "custom-input-group",
                            attrs: {
                              name: "bloquearPegado",
                              type: _vm.verConfirmarClave ? "text" : "password",
                              state: _vm.$v.formConfirmR.clave.$dirty
                                ? !_vm.$v.formConfirmR.clave.$error
                                : null,
                            },
                            model: {
                              value: _vm.$v.formConfirmR.clave.$model,
                              callback: function ($$v) {
                                _vm.$set(
                                  _vm.$v.formConfirmR.clave,
                                  "$model",
                                  $$v
                                )
                              },
                              expression: "$v.formConfirmR.clave.$model",
                            },
                          }),
                          _c(
                            "b-input-group-append",
                            {
                              on: {
                                click: function ($event) {
                                  return _vm.visualizarConfirmarClave()
                                },
                              },
                            },
                            [
                              _c(
                                "b-button",
                                {
                                  staticClass: "icono-ver-clave px-3",
                                  attrs: { variant: "light" },
                                },
                                [
                                  _c("i", {
                                    class:
                                      "las la-eye" +
                                      (_vm.verConfirmarClave ? "-slash" : "") +
                                      " fs-22 primary d-flex",
                                  }),
                                ]
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _c("b-form-invalid-feedback", [
                        _vm._v(
                          _vm._s(_vm.validarCampo(_vm.$v.formConfirmR.clave))
                        ),
                      ]),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "b-col",
                {
                  staticClass: "pt-3 d-flex align-items-center",
                  attrs: { cols: "12", md: "6", lg: "8", "offset-lg": "4" },
                },
                [
                  _c(
                    "b-progress",
                    { staticClass: "w-100", attrs: { max: 5 } },
                    [
                      _c("b-progress-bar", {
                        attrs: {
                          variant: _vm.getVariant,
                          value: _vm.validarClave,
                        },
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "b-col",
        { staticClass: "content-end-center", attrs: { cols: "12" } },
        [
          _c(
            "b-button",
            { attrs: { variant: "danger" }, on: { click: _vm.crearUsuario } },
            [
              _c("i", { staticClass: "las la-check-circle" }),
              _vm._v(" Aceptar "),
            ]
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }