<template>
  <b-row class="px-2">
    <b-col cols="12">
      <h4 class="primary fw-bold">Crea tu usuario y contraseña</h4>
      <p class="mt-3 fs-14 text-justify">
        Diligencia los datos del formulario para crear tu cuenta en la Sucursal Virtual ProgreSER, la información de
        correo electrónico y número de celular son importantes para la activación de tu cuenta. Por favor verifica que
        la información diligenciada sea la correcta.
      </p>
      <p class="mb-1 fs-14 text-justify">
        Ten en cuenta que tu contraseña debe cumplir con lo siguiente:
      </p>
      <p class="mb-0 fs-14">* Ser de mínimo {{ minLength }} caracteres y máximo {{ maxLength }}.</p>
      <p class="mb-0 fs-14">* Debe ser Alfanumérica.</p>
      <p class="mb-0 fs-14">* Debe tener mínimo {{ specialCharacters }} caracteres especiales.</p>
      <p class="mb-0 fs-14">* No puede ser igual a tu identificación.</p>
      <p class="mb-0 fs-14">* Deben coincidir los campos de la contraseña.</p>
      <p class="mb-1 fs-14  text-justify">
        Durante el proceso te informaremos el nivel de seguridad de la contraseña de la siguiente manera:
        <img class="ml-4" src="@/assets/images/anothers/meter.png" />
      </p>
    </b-col>
    <b-col cols="12" class="pb-4">
      <b-form-row>
        <b-col cols="12" md="6" lg="4">
          <b-form-group label="Primer Nombre *">
            <b-form-input
              v-model="$v.formR.primerNombre.$model"
              :state="$v.formR.primerNombre.$dirty ? !$v.formR.primerNombre.$error : null"
            />
            <b-form-invalid-feedback>{{ validarCampo($v.formR.primerNombre) }}</b-form-invalid-feedback>
          </b-form-group>
        </b-col>
        <b-col cols="12" md="6" lg="4">
          <b-form-group label="Segundo Nombre">
            <b-form-input
              v-model="$v.formR.segundoNombre.$model"
              :state="$v.formR.segundoNombre.$dirty ? !$v.formR.segundoNombre.$error : null"
            />
            <b-form-invalid-feedback>{{ validarCampo($v.formR.segundoNombre) }}</b-form-invalid-feedback>
          </b-form-group>
        </b-col>
        <b-col cols="12" md="6" lg="4">
          <b-form-group label="Primer Apellido *">
            <b-form-input
              v-model="$v.formR.primerApellido.$model"
              :state="$v.formR.primerApellido.$dirty ? !$v.formR.primerApellido.$error : null"
            />
            <b-form-invalid-feedback>{{ validarCampo($v.formR.primerApellido) }}</b-form-invalid-feedback>
          </b-form-group>
        </b-col>
        <b-col cols="12" md="6" lg="4">
          <b-form-group label="Segundo Apellido">
            <b-form-input
              v-model="$v.formR.segundoApellido.$model"
              :state="$v.formR.segundoApellido.$dirty ? !$v.formR.segundoApellido.$error : null"
            />
            <b-form-invalid-feedback>{{ validarCampo($v.formR.segundoApellido) }}</b-form-invalid-feedback>
          </b-form-group>
        </b-col>
        <b-col cols="12" md="6" lg="4">
          <b-form-group label="Celular *">
            <b-form-input
              @change="validarCelular"
              v-mask="['(###) ###-####']"
              v-model="$v.formR.celular.$model"
              :state="$v.formR.celular.$dirty ? !$v.formR.celular.$error : null"
            />
            <b-form-invalid-feedback>{{ validarCampo($v.formR.celular) }}</b-form-invalid-feedback>
          </b-form-group>
        </b-col>
        <b-col cols="12" md="6" lg="4">
          <b-form-group label="Dirección *">
            <b-form-input
              v-model="$v.formR.direccion.$model"
              :state="$v.formR.direccion.$dirty ? !$v.formR.direccion.$error : null"
            />
            <b-form-invalid-feedback>{{ validarCampo($v.formR.direccion) }}</b-form-invalid-feedback>
          </b-form-group>
        </b-col>
        <b-col cols="12" md="6" lg="4">
          <b-form-group label="Departamento *">
            <b-form-select
              @change="getCiudadesByDpto"
              :options="list.codigoDepartamento"
              v-model="$v.formR.codigoDepartamento.$model"
              :state="$v.formR.codigoDepartamento.$dirty ? !$v.formR.codigoDepartamento.$error : null"
            >
              <template #first>
                <b-form-select-option :value="null">Seleccione</b-form-select-option>
              </template>
            </b-form-select>
            <b-form-invalid-feedback>{{ validarCampo($v.formR.codigoDepartamento) }}</b-form-invalid-feedback>
          </b-form-group>
        </b-col>
        <b-col cols="12" md="6" lg="4">
          <b-form-group label="Ciudad *">
            <b-form-select
              :options="list.codigoMunicipio"
              v-model="$v.formR.codigoMunicipio.$model"
              :state="$v.formR.codigoMunicipio.$dirty ? !$v.formR.codigoMunicipio.$error : null"
            >
              <template #first>
                <b-form-select-option :value="null">Seleccione</b-form-select-option>
              </template>
            </b-form-select>
            <b-form-invalid-feedback>{{ validarCampo($v.formR.codigoMunicipio) }}</b-form-invalid-feedback>
          </b-form-group>
        </b-col>
        <b-col cols="12" md="6" lg="4">
          <b-form-group label="Correo electrónico *">
            <b-form-input
              v-model="$v.formR.email.$model"
              :state="$v.formR.email.$dirty ? !$v.formR.email.$error : null"
            />
            <b-form-invalid-feedback>{{ validarCampo($v.formR.email) }}</b-form-invalid-feedback>
          </b-form-group>
        </b-col>
        <b-col cols="12" md="6" lg="4">
          <b-form-group label="Confirmar correo electrónico *">
            <b-form-input
              name="bloquearPegado"
              v-model="$v.formConfirmR.email.$model"
              :state="$v.formConfirmR.email.$dirty ? !$v.formConfirmR.email.$error : null"
            />
            <b-form-invalid-feedback>{{ validarCampo($v.formConfirmR.email) }}</b-form-invalid-feedback>
          </b-form-group>
        </b-col>
        <b-col cols="12" md="6" lg="4">
          <b-form-group label="Contraseña *">
            <b-input-group>
              <b-form-input
                class="custom-input-group"
                autocomplete="new-password"
                v-model="$v.formR.clave.$model"
                :type="verClave ? 'text' : 'password'"
                :state="$v.formR.clave.$dirty ? !$v.formR.clave.$error : null"
              />
              <b-input-group-append @click="visualizarClave()">
                <b-button variant="light" class="icono-ver-clave px-3">
                  <i :class="`las la-eye${verClave ? '-slash' : ''} fs-22 primary d-flex`"></i>
                </b-button>
              </b-input-group-append>
            </b-input-group>
            <b-form-invalid-feedback>{{ validarCampo($v.formR.clave) }}</b-form-invalid-feedback>
          </b-form-group>
        </b-col>
        <b-col cols="12" md="6" lg="4">
          <b-form-group label="Confirmar contraseña *">
            <b-input-group>
              <b-form-input
                name="bloquearPegado"
                class="custom-input-group"
                v-model="$v.formConfirmR.clave.$model"
                :type="verConfirmarClave ? 'text' : 'password'"
                :state="$v.formConfirmR.clave.$dirty ? !$v.formConfirmR.clave.$error : null"
              />
              <b-input-group-append @click="visualizarConfirmarClave()">
                <b-button variant="light" class="icono-ver-clave px-3">
                  <i :class="`las la-eye${verConfirmarClave ? '-slash' : ''} fs-22 primary d-flex`"></i>
                </b-button>
              </b-input-group-append>
            </b-input-group>
            <b-form-invalid-feedback>{{ validarCampo($v.formConfirmR.clave) }}</b-form-invalid-feedback>
          </b-form-group>
        </b-col>
        <b-col cols="12" md="6" lg="8" offset-lg="4" class="pt-3 d-flex align-items-center">
          <b-progress :max="5" class="w-100">
            <b-progress-bar :variant="getVariant" :value="validarClave" />
          </b-progress>
        </b-col>
      </b-form-row>
    </b-col>
    <b-col cols="12" class="content-end-center">
      <b-button variant="danger" @click="crearUsuario"> <i class="las la-check-circle" /> Aceptar </b-button>
    </b-col>
  </b-row>
</template>

<script>
import PublicService from "@/app/core/api/public";
import { helpers } from "vuelidate/lib/validators";
import ComunesService from "@/app/core/api/comunes";
import mixinsEvents from "@/app/shared/mixins/mixins-events";
import AlertsService from "@/app/shared/services/alerts.service";
import { required, email, sameAs } from "vuelidate/lib/validators";
import ValidacionIdentidadService from "@/app/core/api/validacion-identidad";

const alpha = helpers.regex("alpha", /^[a-zA-ZñÑ]*$/);

export default {
  mixins: [mixinsEvents],
  data() {
    return {
      progress: 0,
      verClave: false,
      celularValido: true,
      verConfirmarClave: false,
      formConfirmR: { clave: null, email: null },
      list: { codigoMunicipio: [], codigoDepartamento: [], clave: this.$store.getters.listaVencimientoClave },
      formR: {
        clave: null,
        email: null,
        cedula: null,
        usuario: null,
        celular: null,
        direccion: null,
        primerNombre: null,
        segundoNombre: null,
        primerApellido: null,
        segundoApellido: null,
        codigoMunicipio: null,
        codigoDepartamento: null,
        tipoIdentificacion: null,
      },
    };
  },
  computed: {
    getVariant() {
      if (this.progress >= 2 && this.progress < 5) return "warning";
      if (this.progress === 5) return "success";
      return "danger";
    },
    specialCharacters() {
      return this.$store.getters.specialCharacters;
    },
    minLength() {
      return this.$store.getters.minLengthPass;
    },
    maxLength() {
      return this.$store.getters.maxLengthPass;
    },
    validarClave() {
      return this.calcularProgreso();
    },
  },
  validations: {
    formR: {
      clave: { required },
      usuario: { required },
      direccion: { required },
      segundoNombre: { alpha },
      segundoApellido: { alpha },
      email: { required, email },
      codigoMunicipio: { required },
      codigoDepartamento: { required },
      primerNombre: { required, alpha },
      primerApellido: { required, alpha },
      celular: {
        required,
        celValido: function() {
          return this.celularValido;
        },
      },
    },
    formConfirmR: {
      clave: { required },
      email: {
        email,
        required,
        sameAs: sameAs(function() {
          return this.formR.email;
        }),
      },
    },
  },
  created() {
    this.getListas();
    this.formR = { ...this.formR, ...this.$store.getters.dataShared, usuario: this.$store.getters.dataShared.cedula };
  },
  mounted() {
    document.getElementsByName("bloquearPegado").forEach((elem) => {
      elem.onpaste = function(e) {
        e.preventDefault();
      };
    });
  },
  methods: {
    getListas() {
      ComunesService.getDepartamentos().then((response) => {
        this.list.codigoDepartamento = response.data.map((item) => ({ text: item.nombre, value: item.codigo }));
      });
    },
    getCiudadesByDpto(value) {
      this.formR = { ...this.formR, codigoMunicipio: null };
      this.list = { ...this.list, codigoMunicipio: [] };
      ComunesService.getCiudadesByDpto(value).then((response) => {
        this.list.codigoMunicipio = response.data.map((item) => ({ text: item.nombre, value: item.codigo }));
      });
    },
    calcularProgreso() {
      this.progress = 0;
      if (this.formR.clave) {
        // * Ser de mínimo 9 caracteres y máximo 15.
        if (this.formR.clave?.length >= this.minLength && this.formR.clave?.length <= this.maxLength)
          this.progress += 1;
        // * Debe ser alfa numerico
        if (
          this.formR.clave?.match(/\d+/g)?.join("").length >= 1 &&
          this.formR.clave?.match(/[A-Za-z]+/g)?.join("").length >= 1
        )
          this.progress += 1;
        // * Debe tener maximo N caracteres especiales
        if (this.formR.clave?.match(/\W+/g)?.join("").length >= this.specialCharacters) this.progress += 1;
        // * No puede ser igual a tu número de identificación.
        if (this.formR.clave !== this.$store.getters.dataShared.cedula) this.progress += 1;
        // * Deben coincidir los campos
        if (this.formR.clave === this.formConfirmR.clave) this.progress += 1;
      }
      return this.progress;
    },
    validarCelular() {
      if (!this.celularValido) this.celularValido = true;

      if (!this.$v.formR.celular.$error) {
        const body = {
          numeroDocumento: this.formR.cedula,
          celular: this.formatCelular(this.formR.celular),
          origenDatos: this.$store.getters.dataShared.origenDatos,
        };
        ValidacionIdentidadService.validarCelular(body).then((response) => {
          if (!response.data.resultado) {
            AlertsService.error("Registro", response.data.mensaje);
            this.celularValido = false;
          }
        });
      }
    },
    crearUsuario() {
      this.$v.$touch();
      if (this.$v.$invalid) return AlertsService.error("Registro", "Debe completar los campos obligatorios.");
      if (this.progress < 5) return AlertsService.error("Registro", "Debe cumplir las condiciones para la contraseña.");

      const body = {
        ...this.formR,
        clave: this.getHash(this.formR.clave),
        celular: this.formatCelular(this.formR.celular),
      };

      PublicService.crearUsuario(body).then((response) => {
        AlertsService.success("Registro", response.data.mensaje);
        this.$store.commit("updateDataShared", this.formR);
        this.$emit("nextStep");
      });
    },
    visualizarClave() {
      this.verClave = !this.verClave;
    },
    visualizarConfirmarClave() {
      this.verConfirmarClave = !this.verConfirmarClave;
    },
  },
};
</script>
